import AiPage from "components/ai/updated/AiPage";
import { GradientButton } from "components/rhombus-UI/theme/buttons";
import { graphql, StaticQuery } from "gatsby";
import React from "react";
import Helmet from "react-helmet";

import GlobalLayout from "../components/page/GlobalLayout";

export default function AiAnalytics() {
  const renderContent = data => {
    const pageData = {
      header: {
        preTitle: "AI Analytics",
        title: "Save Time with Smarter Security Operations",
        description:
          "Spend less time searching for footage and more time securing your spaces. Rhombus’ suite of AI features speeds up investigations, accelerates emergency response, and helps you stay one step ahead of threats.",
        image: data.headerImage,
      },
      infoSlider: {
        slides: [
          {
            tabTitle: "Facial Recognition",
            preTitle: "PINPOINT ACTIVITY IN SECONDS",
            title: "Human & Facial Recognition",
            description:
              "Say goodbye to manual scrubbing—jump directly to video segments with human activity, find persons-of-interest, and detect unauthorized visitors.",
            image: data.infoSlide1,
          },
          {
            tabTitle: "Audio Analytics",
            preTitle: "Navigate by Noise",
            title: "Audio Analytics",
            description:
              "Layer security footage with smart audio recognition. Detect unusual audio levels, noise fluctuations, glass breaks—and broadcast messages with two-way communication.",
            image: data.infoSlide2,
          },
          {
            tabTitle: "Vehicle Recognition",
            preTitle: "Secure Building Perimeters",
            title: "Vehicle & License Plate Recognition",
            description:
              "Monitor parking lots, garages, and outdoor areas with ease. Search by plate number, detect unauthorized vehicles, and log vehicle data. ",
            image: data.infoSlide3,
          },
          {
            tabTitle: "Color Search",
            preTitle: "accelerate visual search",
            title: "Color Search",
            description:
              "Pinpoint activity based on memorable visual details—search footage for specific clothing and vehicle colors.",
            image: data.infoSlide4,
          },
          {
            tabTitle: "Behavior Detection",
            preTitle: "ADAPTIVE ANALYTICS",
            title: "Unusual Behavior Detection",
            description:
              "Proactively detect suspicious activity—Unusual Behavior Detection automatically identifies deviations from typical activity in your spaces.",
            image: data.infoSlide5,
          },
        ],
      },
      highlights: {
        title: "Stay Ahead of Threats with Proactive AI Alerts",
        image: data.highlightImage,
        list: [
          {
            title: "Real-Time Detection",
            description:
              "Rhombus monitors your spaces 24/7 and automatically sends live alerts with synchronized footage when suspicious activity is detected.",
          },
          {
            title: "Rapid Emergency Response",
            description:
              "Receive immediate notice of potential threats and take swift and informed action. Share video and coordinate with first responders.",
          },
          {
            title: "Customizable Alerts",
            description:
              "Create unlimited custom alerts with unique triggers and parameters. Receive notifications on any device via email, SMS, or mobile app.",
          },
          {
            title: "Machine Learning",
            description:
              "Rhombus calibrates to your environment and optimizes alerts to be accurate, relevant, and helpful for each specific location.",
          },
        ],
      },
      accordionSection: {
        title: "Uncover Actionable Insights with Operational Analytics",
        items: [
          {
            title: "People Counting & Heat Maps ",
            p:
              "Understand occupancy trends and track how people flow through your organization with foot traffic and unique people counts. ",
            img: data.drawer1,
            alt: "People Counting & Heat Maps ",
          },
          {
            title: "Smart IoT Sensors",
            p:
              "Gain deeper insight into your spaces with analytics on temperature, humidity, air quality, building access, and more. ",
            img: data.drawer2,
            alt: "Smart IoT Sensors",
          },
          {
            title: "Layer Third-Party Analytics",
            p:
              "Integrate with third-party software and layer data on camera feeds for operational context on a single pane of glass.",
            img: data.drawer3,
            alt: "Rhombus console showing active threat case",
          },
        ],
      },
      features: [
        {
          icon: data.icon1,
          iconAlt: "AI Made Easy",
          title: "AI Made Easy",
          p:
            "Powerful, easy-to-use AI features for faster search and emergency response.",
        },
        {
          icon: data.icon2,
          iconAlt: "Smarter Investigations",
          title: "Smarter Investigations",
          p:
            "Streamline investigations by searching for specific faces and license plates.",
        },
        {
          icon: data.icon3,
          iconAlt: "security",
          title: "Organized Security Ops",
          p:
            "Events are categorized automatically to find footage quickly and effectively.",
        },
        {
          icon: data.icon4,
          iconAlt: "Proactive Security",
          title: "Proactive Security",
          p:
            "Analyze massive amounts of video and receive alerts without any manual work.",
        },
      ],
      featureSlider: {
        title: "See How AI Analytics Improve Security",
        slides: [
          {
            image: data.slide1,
            title: "Smarter Investigations with Color Search",
            description:
              "Investigate faster and with more insight with color search.",
            link: {
              text: "Read Blog",
              url:
                "/blog/smarter-investigations-with-color-search-and-more-–-april-product-update/",
            },
          },
          {
            image: data.slide2,
            title: "5 Ways AI Video Analytics Improve School Security",
            description:
              "Detect, categorize, and get notified of different types of activity.",
            link: {
              text: "Read Blog",
              url: "/blog/ai-video-analytics-schools/",
            },
          },
          {
            image: data.slide3,
            title: "Boost Console with Advanced Video Analytics",
            description:
              "See how Lumeo brings an advanced layer of video analytics to Rhombus.",
            link: {
              text: "Read Blog",
              url: "/blog/integration-lumeo/",
            },
          },
          {
            image: data.slide4,
            title: "Prevent Retail Employee Theft",
            description:
              "Alleviate retail shrinkage with quick investigations, smart notifications, and more.",
            link: {
              text: "Read Blog",
              url: "/blog/retail-prevent-employee-theft-security-cameras/",
            },
          },
        ],
      },
      bannerSection: {
        title: "Generate ROI with a Faster, Smarter Way to Operate",
        blurb:
          "Rhombus is designed to maximize your efficiency and minimize unnecessary expenses. With proactive analytics, low operational costs, and major savings in user time and labor, the platform makes it easy to generate value.",
        image: data.bannerImage,
        button: () => (
          <GradientButton
            href="/files/generating-positive-roi-with-rhombus.pdf"
            download
          >
            Download ROI Breakdown
          </GradientButton>
        ),
      },
    };
    return (
      <GlobalLayout color="var(--gradient-light)">
        <Helmet>
          <title>Rhombus AI Video Analytics and Smart Alerts</title>
          <meta
            name="description"
            content="Rhombus brings innovative AI features to help organizations see, manage, and respond to threats across their facilities."
          />
        </Helmet>
        <AiPage data={pageData} />
      </GlobalLayout>
    );
  };

  const query = graphql`
    query {
      headerImage: file(
        relativePath: { eq: "components/ai/updated/img/ai-hero-img-min.png" }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 100
            placeholder: BLURRED
          )
        }
      }
      highlightImage: file(
        relativePath: {
          eq: "components/ai/updated/img/highlight-image-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 100
            placeholder: BLURRED
          )
        }
      }
      infoSlide1: file(
        relativePath: {
          eq: "components/ai/updated/img/facial-recognition-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      infoSlide2: file(
        relativePath: {
          eq: "components/ai/updated/img/audio-analytics-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      infoSlide3: file(
        relativePath: { eq: "components/ai/updated/img/license-plate-min.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      infoSlide4: file(
        relativePath: { eq: "components/ITPage/img/stream-line-min.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      infoSlide5: file(
        relativePath: {
          eq: "components/ai/updated/img/unusual-behavior-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      drawer1: file(
        relativePath: { eq: "components/ai/updated/img/heat-maps-min.png" }
      ) {
        childImageSharp {
          gatsbyImageData(quality: 100, placeholder: NONE)
        }
      }
      drawer2: file(
        relativePath: { eq: "components/ai/updated/img/smart-sensors-min.png" }
      ) {
        childImageSharp {
          gatsbyImageData(quality: 100, placeholder: NONE)
        }
      }
      drawer3: file(
        relativePath: { eq: "components/ai/updated/img/point-of-sale-min.png" }
      ) {
        childImageSharp {
          gatsbyImageData(quality: 100, placeholder: NONE)
        }
      }
      contact: file(
        relativePath: { eq: "components/alarms/img/direct-contact-min.png" }
      ) {
        childImageSharp {
          gatsbyImageData(quality: 100, placeholder: NONE)
        }
      }
      emergency: file(
        relativePath: { eq: "components/alarms/img/emergency-dispatch.png" }
      ) {
        childImageSharp {
          gatsbyImageData(quality: 100, placeholder: NONE)
        }
      }
      icon1: file(
        relativePath: { eq: "components/industries/img/icons/learning.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      icon2: file(
        relativePath: {
          eq: "components/industries/img/icons/investigations.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      icon3: file(
        relativePath: { eq: "components/industries/img/icons/features.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      icon4: file(
        relativePath: { eq: "components/industries/img/icons/efficient.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      competitorLogo: file(
        relativePath: { eq: "components/vs/updated/img/Verkada.png" }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      slide1: file(
        relativePath: {
          eq: "components/ai/updated/img/color-search-slide-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      slide2: file(
        relativePath: {
          eq: "components/ai/updated/img/school-security-slide-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      slide3: file(
        relativePath: { eq: "components/ai/updated/img/console-slide-min.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      slide4: file(
        relativePath: { eq: "components/ai/updated/img/theft-slide-min.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      bannerImage: file(
        relativePath: { eq: "components/common/img/r360-gym-min.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
    }
  `;

  return <StaticQuery query={query} render={renderContent} />;
}
